import React from 'react';
import { ChevronRightIcon } from '@heroicons/react/24/solid';

const EntitySelector = ({
    userId,
    entities,
    firstName,
    setLoading,
    onSelect,
}) => {
    return (
        <div className="w-full flex flex-col items-center justify-center py-10">
            <h2 className="text-4xl font-semibold font-literata mb-8">
                Welcome, {firstName}!
            </h2>
            <p className="text-lg font-urbanist text-gray-500 mb-4">
                Choose an entity to continue
            </p>
            <div className="w-full md:w-1/2 bg-white rounded-lg shadow">
                {entities.map((entity, index) => (
                    <div
                        key={entity.entityId}
                        onClick={() => {
                            setLoading(true);
                            onSelect(userId, entity.entityId);
                            setLoading(false);
                        }}
                        className={`flex items-center justify-between p-6 border-b cursor-pointer ${
                            index === entities.length - 1
                                ? 'border-none'
                                : 'border-gray-200'
                        } hover:bg-gray-100`}
                    >
                        <span className="text-md text-gray-900">
                            {entity.name}
                        </span>
                        <ChevronRightIcon className="w-6 h-6 text-cyan-700" />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EntitySelector;
