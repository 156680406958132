import React, { useEffect, useState } from 'react';
import { useGlobalState } from '../../context/GlobalState';
import ErrorToast from './errorToast';

const NotificationQueue = () => {
    const { notificationQueue, dismissNotification } = useGlobalState();
    const [visibleNotifications, setVisibleNotifications] = useState([]);
    const [dismissedNotifications, setDismissedNotifications] = useState([]);

    useEffect(() => {
        const toastDisplayTime = 5000; // show toast for 5s before removing
        const fadeInEffectTime = 200;
        const fadeOutEffectTime = 500;

        notificationQueue.forEach((notification) => {
            const isAlreadyVisible = visibleNotifications.some(
                (notif) => notif.id === notification.id
            );
            const isDismissed = dismissedNotifications.includes(
                notification.id
            );

            // If it's not visible and hasn't been dismissed, add it to the queue
            if (!isAlreadyVisible && !isDismissed) {
                setVisibleNotifications((prev) => [
                    ...prev,
                    { ...notification, visible: false },
                ]);

                // Trigger fade-in after a slight delay
                setTimeout(() => {
                    setVisibleNotifications((prev) =>
                        prev.map((notif) =>
                            notif.id === notification.id
                                ? { ...notif, visible: true }
                                : notif
                        )
                    );
                }, fadeInEffectTime);

                // Set a timeout to hide the notification after the display time
                setTimeout(() => {
                    setVisibleNotifications((prev) =>
                        prev.map((notif) =>
                            notif.id === notification.id
                                ? { ...notif, visible: false }
                                : notif
                        )
                    );

                    // Remove the notification after the fade-out effect
                    setTimeout(() => {
                        dismissNotification(notification.id);
                        setVisibleNotifications((prev) =>
                            prev.filter((notif) => notif.id !== notification.id)
                        );

                        // Add the message to dismissedNotifications to prevent rapid reappearance
                        setDismissedNotifications((prev) => [
                            ...prev,
                            notification.id,
                        ]);

                        // Clear from dismissed after a while to allow repeated errors
                        setTimeout(() => {
                            setDismissedNotifications((prev) =>
                                prev.filter((msg) => msg !== notification.id)
                            );
                        }, 2000); // Adjust this duration if needed
                    }, fadeOutEffectTime);
                }, toastDisplayTime);
            }
        });
    }, [
        notificationQueue,
        dismissNotification,
        visibleNotifications,
        dismissedNotifications,
    ]);

    if (visibleNotifications.length === 0) return null;

    return (
        <div className="fixed top-5 right-5 z-50">
            {visibleNotifications.map((notif, index) => {
                if (!notif || !notif.message || !notif.type) {
                    return null; // Safety check to ensure notif is valid
                }

                switch (notif.type) {
                    case 'error':
                        return (
                            <ErrorToast
                                key={index}
                                message={notif.message}
                                visible={notif.visible}
                                onDismiss={() => dismissNotification(notif.id)}
                            />
                        );
                    // Future cases for success, info, etc. can go here
                    default:
                        return null;
                }
            })}
        </div>
    );
};

export default NotificationQueue;
