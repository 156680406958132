import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import useDashboardData from '../../hooks/useDashboardData';
import { useGlobalState } from '../../context/GlobalState';
import TabContent from './tabContent';
import Sidebar from './sidebar';
import NavigationBar from '../../components/navigationBar';
import Checklist from '../../components/form/checklist';
import CustomSpinner from '../../components/customSpinner';

const Dashboard = () => {
    const { user, isAuthenticated, isLoading } = useAuth0();
    const { workstreamId } = useParams() || null; // get ID from route
    const { currentWorkstream, currentEntity, setFieldsMutate } =
        useGlobalState();
    const [tab, setTab] = useState('HOME');

    const userId = user?.sub.replace('auth0|', '');

    const {
        fieldsData,
        isLoading: dataLoading,
        mutate,
    } = useDashboardData(userId, workstreamId, currentEntity);

    useEffect(() => {
        // Set fieldsMutate in global state when mutate is available
        if (mutate) {
            setFieldsMutate(mutate);
        }
    }, [mutate]);

    if (isLoading || dataLoading || !currentEntity) {
        return <CustomSpinner />;
    }

    return (
        <>
            <NavigationBar />
            <Sidebar
                tab={tab}
                setTab={setTab}
                currentWorkstream={currentWorkstream}
            />

            <div className="flex p-4 mt-20 sm:ml-64 w-3/4">
                <TabContent
                    key={Date.now()} // unique key (current time) to trigger re-render
                    currentTab={tab}
                    isAuthenticated={isAuthenticated}
                    currentWorkstream={currentWorkstream}
                    fields={[...(fieldsData?.fields || [])]}
                />
                {currentWorkstream && (
                    <Checklist fields={[...(fieldsData?.fields || [])]} />
                )}
                {currentWorkstream &&
                    currentWorkstream?.permission == 'VIEWER' && (
                        <div className="fixed top-10 w-80 bg-black text-white shadow-lg rounded-lg p-4 z-40 max-h-[400px] overflow-y-scroll pointer-events-auto flex flex-col justify-center items-center text-lg font-literata">
                            You are in view only mode.
                        </div>
                    )}
            </div>
        </>
    );
};

export default Dashboard;
