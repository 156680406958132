import { FetchEntityData } from '../api/user';
import { useNavigate } from 'react-router-dom';
import { useGlobalState } from '../context/GlobalState';

// Custom hook for handling entity selection
export const useHandleEntitySelect = () => {
    const {
        setCurrentEntity,
        setAllWorkstreams,
        setCurrentWorkstream,
        triggerNotification,
    } = useGlobalState();
    const navigate = useNavigate();

    const handleEntitySelect = async (userId, entityId) => {
        try {
            let response = await FetchEntityData(userId, entityId);

            setCurrentEntity(entityId);
            setAllWorkstreams(response.workstreams);

            // If there's only one workstream, redirect to the workstream route
            if (response.workstreams?.length === 1) {
                const defaultWs = response.workstreams[0];

                setCurrentWorkstream(defaultWs);
                navigate(`/workstream/${defaultWs.workstreamId}`);
            } else {
                setCurrentWorkstream(null);
                navigate('/select');
            }
        } catch (error) {
            console.error(
                'Error in handleEntitySelect (useHandleEntitySelect): ',
                error
            );
            triggerNotification(error.message, 'error');
        }
    };

    return handleEntitySelect;
};

// Custom hook for handling workstream selection
export const useHandleWorkstreamSelect = () => {
    const { setCurrentWorkstream } = useGlobalState();
    const navigate = useNavigate();

    const handleWorkstreamSelect = (ws) => {
        setCurrentWorkstream(ws);
        navigate(`/workstream/${ws.workstreamId}`);
    };

    return handleWorkstreamSelect;
};
