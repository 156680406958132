import MultiSelect from './multiSelect';
import EditableTable from './editableTable';
import MultiFileUpload from './fileHandling/multiFileUpload';
import MultiDimension from './multiDimension';

const RenderFields = ({ fields, workstreamId, isEditable }) => {
    return (
        <>
            {fields.map((field) => (
                <div
                    key={field.name}
                    className={
                        'flex w-3/4 min-h-96 p-4 items-center ' +
                        (field.name === 'EIN Issuance Letter' &&
                            'bg-gray-100 rounded-lg')
                    }
                >
                    <div className="flex-row w-1/4 mx-2">
                        <b className="font-urbanist text-lg">{field.name}</b>
                        <p className="font-urbanist">{field.description}</p>
                    </div>
                    <div
                        className={
                            'flex w-3/4 items-center justify-center ' +
                            (field.type === 'MULTISELECT' ? 'ml-10' : '')
                        }
                    >
                        {field.type === 'FILE' ? (
                            <MultiFileUpload
                                value={field.value}
                                fileType={field.name}
                                workstreamId={workstreamId}
                                isEditable={isEditable}
                            />
                        ) : null}
                        {field.type === 'MULTISELECT' ? (
                            <MultiSelect
                                workstreamId={workstreamId}
                                options={field.options}
                                values={field.values}
                                conditions={field.conditions}
                                form={field.name}
                                isEditable={isEditable}
                            />
                        ) : null}
                        {field.type === 'TABLE' ? (
                            <EditableTable
                                workstreamId={workstreamId}
                                form={field.name}
                                columns={field.columns}
                                initialValues={field.values}
                                isEditable={isEditable}
                            />
                        ) : null}
                        {field.type === 'MULTIDIMENSION' ? (
                            <MultiDimension
                                field={field}
                                workstreamId={workstreamId}
                                isEditable={isEditable}
                            />
                        ) : null}
                    </div>
                </div>
            ))}
        </>
    );
};

export default RenderFields;
