import React from 'react';
import { Dropdown } from 'flowbite-react';
import { HiCheck } from 'react-icons/hi';
import { VscBlank } from 'react-icons/vsc';

const toTitleCase = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const WorkstreamDropdown = ({ workstreams, currentWsId, onSelect }) => {
    return (
        <Dropdown label="Workstreams" inline={true}>
            {workstreams.map((ws) => (
                <Dropdown.Item
                    key={ws.workstreamId}
                    icon={
                        currentWsId
                            ? ws.workstreamId === currentWsId
                                ? HiCheck
                                : VscBlank
                            : null
                    }
                    onClick={() => onSelect(ws)}
                    disabled={ws.id === currentWsId} // Disable if current workstream
                >
                    {ws.name} ({toTitleCase(ws.permission)})
                </Dropdown.Item>
            ))}
        </Dropdown>
    );
};

export default WorkstreamDropdown;
