import React, { useState } from 'react';
import { Button, Timeline } from 'flowbite-react';
import { HiCheckCircle } from 'react-icons/hi';

const Checklist = ({ fields }) => {
    const [collapsed, setCollapsed] = useState(false);

    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    };

    return (
        <div className="fixed top-10 right-10 w-80 bg-white shadow-lg rounded-lg p-4 z-40 mt-20 sm:ml-64 max-h-[400px] overflow-y-scroll pointer-events-auto flex flex-col justify-between">
            {collapsed ? (
                <div className="flex w-full text-center">
                    <Button
                        onClick={toggleCollapse}
                        className="w-full bg-white border-gray-100 enabled:hover:bg-gray-200 text-black"
                    >
                        Expand
                    </Button>
                </div>
            ) : (
                <>
                    <div className="flex overflow-y-auto">
                        <Timeline>
                            {fields.map((field, index) => (
                                <Timeline.Item key={index}>
                                    <Timeline.Point
                                        icon={() => <HiCheckCircle />}
                                    />
                                    <Timeline.Content>
                                        <Timeline.Title>
                                            {field.name}
                                        </Timeline.Title>
                                        <Timeline.Body>
                                            {field.description}
                                        </Timeline.Body>
                                    </Timeline.Content>
                                </Timeline.Item>
                            ))}
                        </Timeline>
                    </div>
                    <div className="text-center mt-4">
                        <Button
                            onClick={toggleCollapse}
                            className="w-full bg-white border-gray-100 enabled:hover:bg-gray-200 text-black"
                        >
                            Collapse
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};

export default Checklist;
