import React from 'react';
import ShortForm from './shortForm';
import MultiSelect from './multiSelect';
import MultiFileUpload from './fileHandling/multiFileUpload';
import { db } from '../../utils/instantDb';

const MultiDimension = ({ field, workstreamId, isEditable }) => {
    // Initialize room and track typing activity for all subfields
    const typingIndicatorName = `multidimension-${field.name}`;
    const room = db.room('typing-indicator', workstreamId);
    const { active } = room.useTypingIndicator(typingIndicatorName);

    return (
        <div className="flex flex-col w-full">
            {field.fields.map((sub_field) => {
                if (sub_field.type === 'SHORTFORM') {
                    return (
                        <ShortForm
                            key={sub_field.name}
                            workstreamId={workstreamId}
                            name={sub_field.name}
                            value={sub_field.values}
                            form={field.name}
                            subForm={sub_field.name}
                            /* ShortForm is the only component that needs a typing indicator */
                            typingIndicatorName={typingIndicatorName}
                            isEditable={isEditable}
                        />
                    );
                } else if (sub_field.type === 'MULTISELECT') {
                    return (
                        <MultiSelect
                            key={sub_field.name}
                            workstreamId={workstreamId}
                            options={sub_field.options}
                            values={sub_field.values}
                            conditions={sub_field.conditions}
                            form={field.name}
                            subForm={sub_field.name}
                            isEditable={isEditable}
                        />
                    );
                } else if (sub_field.type === 'FILE') {
                    return (
                        <MultiFileUpload
                            key={sub_field.name}
                            value={sub_field.value}
                            fileType={sub_field.name}
                            workstreamId={workstreamId}
                            isEditable={isEditable}
                        />
                    );
                }
                return null;
            })}

            {/* Display typing indicator for others editing any subfield */}
            <div className="text-sm text-gray-500 mt-2">
                {active.length === 0
                    ? '\u00A0' // Render nothing if no one is typing
                    : active.length === 1
                      ? `${active[0].name} is editing...`
                      : `${active[0].name} and others are editing...`}
            </div>
        </div>
    );
};

export default MultiDimension;
