import LoginButton from '../components/auth/loginButton';
import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import '../index.css';

const Home = () => {
    const { isAuthenticated, isLoading } = useAuth0();

    useEffect(() => {
        if (!isLoading && isAuthenticated) window.location.replace('/select');
    }, [isLoading, isAuthenticated]);

    return (
        <div className="flex w-full h-full items-center justify-center">
            <LoginButton />
        </div>
    );
};

export default Home;
