import React from 'react';
import { Dropdown } from 'flowbite-react';
import { HiCheck } from 'react-icons/hi';
import { VscBlank } from 'react-icons/vsc';

const EntityDropdown = ({ userId, entities, currentEntityId, onSelect }) => {
    return (
        <Dropdown label="Entities" inline={true}>
            {entities.map((entity) => (
                <Dropdown.Item
                    key={entity.entityId}
                    icon={
                        currentEntityId
                            ? entity.entityId === currentEntityId
                                ? HiCheck
                                : VscBlank
                            : null
                    }
                    onClick={() => onSelect(userId, entity.entityId)}
                    disabled={entity.entityId === currentEntityId} // Disable if current entity
                >
                    {entity.name}
                </Dropdown.Item>
            ))}
        </Dropdown>
    );
};

export default EntityDropdown;
