import React from 'react';
import SidebarItem from './sidebarItem';
import LogoutButton from '../../components/auth/logoutButton';

const Sidebar = ({ currentWorkstream, tab, setTab }) => {
    return (
        <aside
            id="logo-sidebar"
            className="fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform -translate-x-full bg-white border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700"
            aria-label="Sidebar"
        >
            <div className="h-full px-3 pb-4 overflow-y-auto bg-white dark:bg-gray-800">
                <ul className="space-y-2 font-medium">
                    {currentWorkstream && (
                        <>
                            <SidebarItem
                                tabName="HOME"
                                currentTab={tab}
                                setTab={setTab}
                                svgName="FourBlock"
                                label="Home"
                            />
                            <SidebarItem
                                tabName="REPORTS"
                                currentTab={tab}
                                setTab={setTab}
                                svgName="PieChart"
                                label="Reports"
                            />
                            <SidebarItem
                                tabName="TASKS"
                                currentTab={tab}
                                setTab={setTab}
                                svgName="MailBox"
                                label="Tasks"
                            />
                            <SidebarItem
                                tabName="FILE_MANAGER"
                                currentTab={tab}
                                setTab={setTab}
                                svgName="EditFile"
                                label="File Manager"
                            />
                        </>
                    )}
                    <li>
                        <LogoutButton />
                    </li>
                </ul>
            </div>
        </aside>
    );
};

export default Sidebar;
