import { Label, Textarea } from 'flowbite-react';
import { useState, useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { updateShortFormValue } from '../../api/update';
import { useGlobalState } from '../../context/GlobalState';
import { db } from '../../utils/instantDb';

const ShortForm = ({
    workstreamId,
    name,
    value,
    form,
    subForm,
    typingIndicatorName,
    isEditable,
}) => {
    const [sfValue, setSfValue] = useState(value);
    const { user } = useAuth0();
    const { triggerNotification, fieldsMutate } = useGlobalState();

    // Initialize room for typing indicator and track local typing activity
    const room = db.room('typing-indicator', workstreamId);
    const { inputProps } = room.useTypingIndicator(typingIndicatorName);

    const inactivityTimerRef = useRef(null);

    const handleSfChange = (e) => {
        const newSfValue = e.target.value;
        setSfValue(newSfValue);

        // Clear the existing inactivity timer
        if (inactivityTimerRef.current) {
            clearTimeout(inactivityTimerRef.current);
        }

        // Start a new inactivity timer
        inactivityTimerRef.current = setTimeout(async () => {
            // Trigger the API call without blurring...
            try {
                await updateShortFormValue(
                    user?.sub,
                    workstreamId,
                    form,
                    subForm,
                    newSfValue
                );

                // Revalidate SWR cache
                if (fieldsMutate.current) await fieldsMutate.current();
            } catch (error) {
                console.error('Error in inactivity timer (ShortForm): ', error);
                triggerNotification(error.message, 'error');
            }
        }, 2500); // if it's been 2.5 seconds.
    };

    const handleSfBlur = async () => {
        // Clear the inactivity timer since we're blurring now
        if (inactivityTimerRef.current) {
            clearTimeout(inactivityTimerRef.current);
            inactivityTimerRef.current = null;
        }

        try {
            await updateShortFormValue(
                user?.sub,
                workstreamId,
                form,
                subForm,
                sfValue
            );
        } catch (error) {
            console.error('Error in handleSfBlur (ShortForm): ', error);
            triggerNotification(error.message, 'error');
        }
    };

    // Clean up on unmount
    useEffect(() => {
        return () => {
            if (inactivityTimerRef.current) {
                clearTimeout(inactivityTimerRef.current);
            }
        };
    }, []);

    return (
        <div className="flex w-full m-4">
            <Label htmlFor="comment" value={name} />
            <Textarea
                placeholder="Enter your response here"
                value={sfValue}
                onChange={handleSfChange}
                onBlur={handleSfBlur}
                onKeyDown={inputProps.onKeyDown}
                disabled={!isEditable}
                required
                rows={1}
            />
        </div>
    );
};

export default ShortForm;
