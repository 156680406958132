import { useEffect } from 'react';
import useSWR from 'swr';
import { FetchWSFields, verifyWorkstreamOwnership } from '../api/user';
import { useNavigate } from 'react-router-dom';
import { useGlobalState } from '../context/GlobalState';

const useDashboardData = (userId, workstreamId, currentEntity) => {
    const navigate = useNavigate();
    const {
        setCurrentWorkstream,
        setCurrentEntity,
        setAllEntities,
        setAllWorkstreams,
        triggerNotification,
    } = useGlobalState();

    // Custom fetcher function
    const fetcher = (keyArray) => {
        const [key, ...params] = keyArray;

        switch (key) {
            case 'verifyWorkstreamOwnership':
                return verifyWorkstreamOwnership(...params);
            case 'FetchWSFields':
                return FetchWSFields(...params);
            default:
                throw new Error('Invalid fetcher key!');
        }
    };

    // Verify workstream ownership
    const { data: verificationData, error: verificationError } = useSWR(
        userId && workstreamId && !currentEntity
            ? ['verifyWorkstreamOwnership', userId, workstreamId]
            : null,
        fetcher
    );

    // Fetch workstream fields with polling
    const {
        data: fieldsData,
        error: fieldsError,
        mutate,
    } = useSWR(workstreamId ? ['FetchWSFields', workstreamId] : null, fetcher);

    // Handle verification data
    useEffect(() => {
        if (verificationData) {
            // get all possible workstreams and narrow down the one that the user wants based on URL param
            const allWorkstreams = verificationData.workstreams;
            const selectedWorkstream = allWorkstreams.find(
                (ws) => ws.workstreamId === workstreamId
            );

            setCurrentWorkstream(selectedWorkstream);
            setAllWorkstreams(allWorkstreams);

            setCurrentEntity(verificationData.entity_id);
            setAllEntities(verificationData.all_entities);
        } else if (verificationError) {
            console.error(
                'Error in verifyWorkstreamOwnership:',
                verificationError
            );
            triggerNotification(verificationError.message, 'error');
            navigate('/select');
        }
    }, [verificationData, verificationError]);

    // Handle fields errors
    useEffect(() => {
        if (fieldsError) {
            console.error('Error in FetchWSFields:', fieldsError);
            triggerNotification(fieldsError.message, 'error');
            navigate('/select');
        }
    }, [fieldsError]);

    return {
        fieldsData,
        isLoading: !fieldsData && !fieldsError,
        mutate, // Return mutate so it can be used in Dashboard.js
    };
};

export default useDashboardData;
