export const ACCEPTABLE_IMAGE_FORMATS = [
    '.jpg',
    '.jpeg',
    '.png',
    '.webp',
    '.heic',
];
export const ACCEPTABLE_DOCUMENT_FORMATS = [
    '.pdf',
    '.xlsx',
    '.xls',
    '.csv',
    '.numbers',
    '.docx',
    '.doc',
    '.txt',
    '.pages',
];

// Combine all acceptable formats into one list
export const ACCEPTABLE_FILE_FORMATS = [
    ...ACCEPTABLE_IMAGE_FORMATS,
    ...ACCEPTABLE_DOCUMENT_FORMATS,
];
