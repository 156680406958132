import React from 'react';
import { Spinner } from 'flowbite-react';

const CustomSpinner = ({ ariaLabel = 'Loading Spinner', size = 'xl' }) => {
    return (
        <div className="w-full flex items-center justify-center py-10">
            <Spinner aria-label={ariaLabel} size={size} />
        </div>
    );
};

export default CustomSpinner;
