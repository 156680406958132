import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Button, Modal } from 'flowbite-react';
import { ACCEPTABLE_IMAGE_FORMATS } from './fileFormats';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

// Set workerSrc
const workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = workerSrc;

const FilePreviewContent = ({
    fileUrl,
    extension,
    numPages,
    onDocumentLoadSuccess,
}) => {
    const lowerExtension = extension?.toLowerCase();

    if (lowerExtension === '.pdf') {
        return (
            <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
                {Array.from(new Array(numPages), (el, index) => (
                    <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        renderTextLayer={false}
                    />
                ))}
            </Document>
        );
    } else if (ACCEPTABLE_IMAGE_FORMATS.includes(lowerExtension)) {
        return <img src={fileUrl} alt="Uploaded file preview" />;
    } else {
        return <p>Preview not available for this file type.</p>;
    }
};

const FilePreview = ({
    fileUrl,
    extension,
    numPages,
    onDocumentLoadSuccess,
    isModalOpen,
    closeModal,
}) => {
    return (
        <Modal show={isModalOpen} onClose={closeModal}>
            <Modal.Header>File Preview</Modal.Header>
            <Modal.Body>
                {fileUrl && (
                    <div className="w-full p-0 m-0">
                        <FilePreviewContent
                            fileUrl={fileUrl}
                            extension={extension}
                            numPages={numPages}
                            onDocumentLoadSuccess={onDocumentLoadSuccess}
                        />
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={closeModal}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default FilePreview;
