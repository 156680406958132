let cachedPublicKey = null;

// Convert PEM format to ArrayBuffer
const convertPemToArrayBuffer = (pem) => {
    // Remove the header, footer, and whitespace
    const b64Lines = pem
        .replace(/-----(BEGIN|END) PUBLIC KEY-----/g, '')
        .replace(/\s+/g, ''); // Remove all whitespace and newlines

    // Decode the Base64 string into binary data
    let binaryDerString;
    try {
        binaryDerString = window.atob(b64Lines);
    } catch (error) {
        throw new Error('Invalid Base64 encoding in public key.');
    }

    const binaryDer = new Uint8Array(binaryDerString.length);
    for (let i = 0; i < binaryDerString.length; i++) {
        binaryDer[i] = binaryDerString.charCodeAt(i);
    }

    return binaryDer.buffer;
};

// Fetch and process the public RSA key
const fetchPublicKey = async () => {
    if (cachedPublicKey) {
        return cachedPublicKey; // Use cached key if available
    }

    // Access the public key from the environment variable
    const publicKeyPem = process.env.REACT_APP_RSA_PUBLIC_KEY;

    if (!publicKeyPem) {
        throw new Error('Public key not found in environment variables');
    }

    // Convert the PEM format to ArrayBuffer
    const publicKeyArrayBuffer = convertPemToArrayBuffer(publicKeyPem);

    // Import the RSA public key into the WebCrypto API
    cachedPublicKey = await crypto.subtle.importKey(
        'spki',
        publicKeyArrayBuffer,
        {
            name: 'RSA-OAEP',
            hash: 'SHA-256',
        },
        true,
        ['encrypt']
    );

    return cachedPublicKey;
};

// Encrypt the file using AES-GCM
const encryptFile = async (file) => {
    // Generate AES key
    const aesKey = await crypto.subtle.generateKey(
        { name: 'AES-GCM', length: 256 },
        true,
        ['encrypt', 'decrypt']
    );

    // Generate initialization vector (IV)
    const iv = crypto.getRandomValues(new Uint8Array(12)); // 12 bytes IV for AES-GCM

    // Encrypt the file content
    const fileArrayBuffer = await file.arrayBuffer(); // Get file content as ArrayBuffer
    const encryptedFile = await crypto.subtle.encrypt(
        { name: 'AES-GCM', iv },
        aesKey,
        fileArrayBuffer
    );

    // Check the length of the encrypted file
    console.log('Encrypted file length: ', encryptedFile.byteLength);

    // Split the encrypted file into ciphertext and authentication tag
    const tagLength = 16; // AES-GCM authentication tag length is 16 bytes
    if (encryptedFile.byteLength < tagLength) {
        throw new Error(
            'Encrypted file is too short to contain an authentication tag.'
        );
    }

    const ciphertext = encryptedFile.slice(
        0,
        encryptedFile.byteLength - tagLength
    );
    const authTag = encryptedFile.slice(encryptedFile.byteLength - tagLength);

    // Export the AES key for RSA encryption
    const exportedAesKey = await crypto.subtle.exportKey('raw', aesKey);

    return { ciphertext, exportedAesKey, iv, authTag };
};

// Encrypt AES key using RSA public key
const encryptAesKeyWithRsa = async (publicKey, aesKey) => {
    // Encrypt the AES key using the server's public RSA key
    const encryptedAesKey = await crypto.subtle.encrypt(
        { name: 'RSA-OAEP' },
        publicKey,
        aesKey
    );

    return encryptedAesKey;
};

// Encrypt the file and prepare data for form submission
export const prepareEncryptedFileData = async (file) => {
    // Step 1: Fetch the public RSA key
    const publicKey = await fetchPublicKey();

    // Step 2: Encrypt the file and generate AES key and IV
    const { ciphertext, exportedAesKey, iv, authTag } = await encryptFile(file);

    // Step 3: Encrypt the AES key using the public RSA key
    const encryptedAesKey = await encryptAesKeyWithRsa(
        publicKey,
        exportedAesKey
    );

    // Step 4: Convert ciphertext and AES key to blobs
    const ciphertextBlob = new Blob([ciphertext]);
    const encryptedAesKeyBlob = new Blob([encryptedAesKey]);

    // Step 5: Convert IV and authTag to Base64
    const ivBase64 = btoa(String.fromCharCode(...new Uint8Array(iv)));
    const authTagBase64 = btoa(String.fromCharCode(...new Uint8Array(authTag)));

    // Step 6: Return all the necessary data for formData
    return { ciphertextBlob, encryptedAesKeyBlob, ivBase64, authTagBase64 };
};
