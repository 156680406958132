import React from 'react';
import Tasks from '../tasks';
import Reports from '../reports';
import FileManager from '../fileManager';
import CoreForm from '../../components/coreForm';

const TabContent = ({
    currentTab,
    isAuthenticated,
    currentWorkstream,
    fields,
}) => {
    if (currentTab === 'HOME') {
        if (isAuthenticated && fields) {
            return (
                <CoreForm
                    key={currentWorkstream.id}
                    fields={fields}
                    workstream={currentWorkstream}
                />
            );
        }
    } else if (currentTab === 'REPORTS') {
        return <Reports />;
    } else if (currentTab === 'TASKS') {
        return <Tasks />;
    } else if (currentTab === 'FILE_MANAGER') {
        return <FileManager />;
    } else {
        return <></>;
    }
};

export default TabContent;
