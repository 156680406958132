import React from 'react';
import SVGRender from '../../assets/svgs/svgrender';

const SidebarItem = ({ tabName, currentTab, setTab, svgName, label }) => {
    return (
        <li>
            <a
                onClick={() => setTab(tabName)}
                className={
                    'flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group ' +
                    (currentTab === tabName && 'bg-gray-100')
                }
            >
                {currentTab === tabName ? (
                    <SVGRender svg_name={svgName} dark={true} />
                ) : (
                    <SVGRender svg_name={svgName} dark={false} />
                )}
                <span className="flex-1 ms-3 whitespace-nowrap">{label}</span>
            </a>
        </li>
    );
};

export default SidebarItem;
