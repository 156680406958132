import axios from 'axios';
import baseURL from './baseURL';

export const FetchUserData = async (userId) => {
    try {
        const fetch_url = `${baseURL}/user?id=${userId}`;
        const response = await axios.get(fetch_url);

        return response.data;
    } catch (error) {
        console.error('Error in FetchUserData: ', error);
        throw new Error(
            error?.response?.data?.message || 'Error fetching user data!'
        );
    }
};

export const FetchEntityData = async (userId, entityId) => {
    try {
        const fetch_url = `${baseURL}/entity?user_id=${userId}&id=${entityId}`;
        const response = await axios.get(fetch_url);

        return response.data;
    } catch (error) {
        console.error('Error in FetchEntityData: ', error);
        throw new Error(
            error?.response?.data?.message || 'Error fetching entity data!'
        );
    }
};

export const FetchWSFields = async (workstreamId) => {
    try {
        const fetch_url = `${baseURL}/ws?id=${workstreamId}`;
        const response = await axios.get(fetch_url);

        return response.data;
    } catch (error) {
        console.error('Error in FetchWSFields: ', error);
        throw new Error(
            error?.response?.data?.message ||
                'Error fetching workstream fields!'
        );
    }
};

export const verifyWorkstreamOwnership = async (userId, workstreamId) => {
    try {
        const fetch_url = `${baseURL}/verify_ws?user_id=${userId}&ws_id=${workstreamId}`;
        const response = await axios.get(fetch_url);

        return response.data;
    } catch (error) {
        console.error('Error in verifyWorkstreamOwnership: ', error);
        throw new Error(
            error?.response?.data?.message ||
                'Error verifying workstream ownership!'
        );
    }
};
