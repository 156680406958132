import axios from 'axios';
import baseURL from './baseURL';

export const updateMultiSelectValues = async (
    userId,
    workstreamId,
    form,
    subForm,
    newVals
) => {
    try {
        const params = new URLSearchParams({
            user_id: userId.replace('auth0|', ''),
            ws_id: workstreamId,
            name: subForm || form,
            field_name: 'MULTISELECT',
            values: newVals,
        });

        const uploadURL = `${baseURL}/update_ws?${params.toString()}`;

        const response = await axios.post(
            uploadURL,
            {},
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        return response.data;
    } catch (error) {
        console.error('Error in updateMultiSelectValues: ', error);
        throw new Error(
            error?.response?.data?.message || 'Error updating the form!'
        );
    }
};

export const updateShortFormValue = async (
    userId,
    workstreamId,
    formName,
    subForm,
    newVal
) => {
    try {
        let field_name = subForm != null ? subForm : 'NONE';

        const params = new URLSearchParams({
            user_id: userId.replace('auth0|', ''),
            ws_id: workstreamId,
            name: field_name || formName,
            field_name: 'SHORTFORM',
            value: newVal,
        });

        const uploadURL = `${baseURL}/update_ws?${params.toString()}`;

        const response = await axios.post(
            uploadURL,
            {},
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        return response.data;
    } catch (error) {
        console.error('Error in updateShortFormValue: ', error);
        throw new Error(
            error?.response?.data?.message || 'Error updating the short form!'
        );
    }
};

export const updateTableValues = async (
    userId,
    workstreamId,
    formName,
    updatedRows
) => {
    try {
        const params = new URLSearchParams({
            user_id: userId.replace('auth0|', ''),
            ws_id: workstreamId,
            name: formName,
            field_name: 'TABLE',
        });

        let uploadURL = `${baseURL}/update_ws?${params.toString()}`;

        const response = await axios.post(uploadURL, {
            values: updatedRows, // Sending rows as a JSON array in the request body
        });

        return response.data;
    } catch (error) {
        console.error('Error in updateTableValues: ', error);
        throw new Error(
            error?.response?.data?.message || 'Error updating the table!'
        );
    }
};
