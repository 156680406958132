import { Checkbox, Label } from 'flowbite-react';
import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { updateMultiSelectValues } from '../../api/update';
import MultiFileUpload from './fileHandling/multiFileUpload';
import { useGlobalState } from '../../context/GlobalState';

const MultiSelect = ({
    workstreamId,
    options,
    values,
    form,
    isEditable,
    subForm = null,
    conditions = [],
}) => {
    let [selectValues, setSelectValues] = useState(values);
    const { user } = useAuth0();
    const { triggerNotification, fieldsMutate } = useGlobalState();

    const handleSelectChange = async (e) => {
        let newSelectValues = [...selectValues];
        newSelectValues[options.indexOf(e.target.id)] = e.target.checked
            ? '1'
            : '0';
        setSelectValues(newSelectValues);

        try {
            await updateMultiSelectValues(
                user?.sub,
                workstreamId,
                form,
                subForm,
                newSelectValues
            );

            // Revalidate SWR cache
            if (fieldsMutate.current) await fieldsMutate.current();
        } catch (error) {
            console.error('Error in handleSelectChange: ', error);
            triggerNotification(error.message, 'error');
        }
    };

    // Function to determine if dependent fields should be shown
    const showDependentFields = (dependent) => {
        const index = options.indexOf(dependent);
        // Show if the value is '1'
        return selectValues[index] === '1';
    };

    return (
        <div
            className="flex w-full justify-center flex-col gap-4"
            id="checkbox"
        >
            {selectValues
                ? options.map((option, index) => {
                      return (
                          <div key={option} className="flex items-center gap-2">
                              <Checkbox
                                  id={option}
                                  onChange={handleSelectChange}
                                  checked={selectValues[index] === '1'}
                                  disabled={!isEditable}
                              />
                              <Label htmlFor={option}>{option}</Label>
                          </div>
                      );
                  })
                : null}

            {/* Render the fields inside conditions if dependencies are satisfied */}
            {conditions.map((condition) =>
                showDependentFields(condition.dependent) ? (
                    <div key={condition.dependent} className="mt-4">
                        {condition.fields.map((sub_field) => {
                            if (sub_field.type === 'FILE') {
                                return (
                                    <MultiFileUpload
                                        key={sub_field.name}
                                        value={sub_field.value}
                                        fileType={sub_field.name}
                                        workstreamId={workstreamId}
                                        isEditable={isEditable}
                                    />
                                );
                            }
                            return null;
                        })}
                    </div>
                ) : null
            )}
        </div>
    );
};

export default MultiSelect;
