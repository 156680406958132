import DarkEditFile from './files/DarkEditFile.js';
import DarkFourBlock from './files/DarkFourBlock.js';
import DarkMailBox from './files/DarkMailBox.js';
import DarkPieChart from './files/DarkPieChart.js';
import EditFile from './files/EditFile.js';
import FourBlock from './files/FourBlock.js';
import MailBox from './files/MailBox.js';
import PieChart from './files/PieChart.js';

const SVGs = {
    FourBlock: [<FourBlock />, <DarkFourBlock />],
    PieChart: [<PieChart />, <DarkPieChart />],
    MailBox: [<MailBox />, <DarkMailBox />],
    EditFile: [<EditFile />, <DarkEditFile />],
};

const SVGRender = ({ svg_name, dark = false }) => {
    return dark ? SVGs[svg_name][1] : SVGs[svg_name][0];
};

export default SVGRender;
