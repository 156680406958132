import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'flowbite-react';
import { updateTableValues } from '../../api/update';
import { useAuth0 } from '@auth0/auth0-react';
import { useGlobalState } from '../../context/GlobalState';
import { db } from '../../utils/instantDb';

const EditableTable = ({
    workstreamId,
    form,
    columns = [],
    initialValues = [],
    isEditable,
}) => {
    const [rows, setRows] = useState(initialValues); // rows as arrays
    const { user } = useAuth0();
    const { triggerNotification, fieldsMutate } = useGlobalState();
    const typingTimeoutRef = useRef(null); // Ref to store timeout ID

    // Initialize room for typing indicator and track activity
    const room = db.room('typing-indicator', workstreamId);
    const { active, inputProps } = room.useTypingIndicator(`table-${form}`);

    // Have at least one empty row when no rows are available
    useEffect(() => {
        if (rows.length === 0) {
            setRows([Array(columns.length).fill('')]);
        }
    }, [columns, rows]);

    const hasEmptyRow = () => {
        return rows.some((row) => row.every((cell) => cell === ''));
    };

    const handleAddRow = () => {
        if (!isEditable) {
            return;
        }
        // Add an empty row with the same number of columns
        setRows([...rows, Array(columns.length).fill('')]);
    };

    // Function to handle API call for updating values
    const updateValues = async (updatedRows) => {
        try {
            await updateTableValues(user?.sub, workstreamId, form, updatedRows);

            // Revalidate SWR cache
            if (fieldsMutate.current) await fieldsMutate.current();
        } catch (error) {
            console.error('Error in updateValues (EditableTable): ', error);
            triggerNotification(error.message, 'error');
        }
    };

    const handleChange = (rowIndex, colIndex, e) => {
        if (!isEditable) return;

        const updatedRows = [...rows];
        updatedRows[rowIndex][colIndex] = e.target.value; // Update the specific cell value
        setRows(updatedRows);

        // Clear any existing timeout to prevent premature API calls
        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }

        // Set a new timeout to call the API after the user has stopped typing
        typingTimeoutRef.current = setTimeout(() => {
            updateValues(updatedRows);
        }, 2000); // Keep a delay of 2.5s
    };

    // Handle the immediate update when the input field loses focus
    const handleBlur = (rowIndex, colIndex) => {
        if (!isEditable) return;

        // Clear the timeout to make sure we do not wait for the debounce
        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }

        const updatedRows = [...rows];
        updateValues(updatedRows); // Trigger immediate update on blur
    };

    return (
        <div className="bg-white shadow-md rounded-lg p-6 max-w-3xl mx-auto">
            <table className="w-full text-left text-gray-600">
                <thead>
                    <tr className="border-b border-gray-200">
                        {columns.length > 0 ? (
                            columns.map((column, colIndex) => (
                                <th
                                    key={colIndex}
                                    className="py-3 px-4 font-semibold text-gray-800"
                                >
                                    {column.name}
                                </th>
                            ))
                        ) : (
                            <th className="py-3 px-4 font-semibold text-gray-800">
                                No columns defined
                            </th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, rowIndex) => (
                        <tr key={rowIndex} className="border-b border-gray-200">
                            {row.map((cell, colIndex) => (
                                <td key={colIndex} className="py-2 px-4">
                                    <input
                                        type="text"
                                        value={cell}
                                        onChange={(e) =>
                                            handleChange(rowIndex, colIndex, e)
                                        }
                                        onBlur={() =>
                                            handleBlur(rowIndex, colIndex)
                                        }
                                        onKeyDown={inputProps.onKeyDown}
                                        className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        placeholder={`Enter ${columns[colIndex]?.name || ''}`}
                                        disabled={!isEditable}
                                    />
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="flex justify-between items-center mt-4">
                <Button
                    onClick={handleAddRow}
                    className="rounded-md"
                    disabled={hasEmptyRow() || !isEditable}
                >
                    Add Row
                </Button>

                {/* Display typing indicator for others editing any subfield */}
                <div className="text-sm text-gray-500">
                    {active.length === 0
                        ? '\u00A0' // Render nothing if no one is typing
                        : active.length === 1
                          ? `${active[0].name} is editing...`
                          : `${active[0].name} and others are editing...`}
                </div>
            </div>
        </div>
    );
};

export default EditableTable;
