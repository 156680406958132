import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
    Navigate,
    Outlet,
} from 'react-router-dom';
import { SWRConfig } from 'swr';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import * as amplitude from '@amplitude/analytics-browser';
import { GlobalStateProvider } from './context/GlobalState';
import Home from './screens/home';
import Dashboard from './screens/dashboard/dashboard';
import Selection from './screens/selection';
import NotFound from './screens/notFound';
import CustomSpinner from './components/customSpinner';
import NotificationQueue from './components/notifications/notificationQueue';
import * as Sentry from '@sentry/react';
import './fonts.css';

amplitude.init('e672511a3e4cf1272ccb454588bfb7d2', { autocapture: true });

Sentry.init({
    dsn: 'https://c2bf8ecc98829caaa777456e16c513b0@o4508044178554880.ingest.us.sentry.io/4508044180258816',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', 'https://rivetapi.up.railway.app/'],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Layout component to handle authentication checks
const ProtectedRoute = () => {
    const { isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return <CustomSpinner />;
    }

    if (!isAuthenticated) {
        return <Navigate to="/auth" replace />;
    }

    return <Outlet />; // Render child routes if authenticated
};

// Define routes
const router = createBrowserRouter([
    {
        path: '/',
        element: <Navigate to={'/auth'} replace={true} />,
    },
    {
        path: '/auth',
        element: <Home />,
    },
    {
        element: <ProtectedRoute />, // Wrap all protected routes inside this layout
        children: [
            {
                path: '/select',
                element: <Selection />,
            },
            {
                path: '/workstream/:workstreamId',
                element: <Dashboard />,
            },
        ],
    },
    {
        path: '*',
        element: <NotFound />,
    },
]);

const App = () => {
    return (
        <SWRConfig
            value={{
                refreshInterval: 1000,
                dedupingInterval: 500,
                revalidateOnMount: true,
                revalidateOnFocus: false,
                revalidateOnReconnect: true,
            }}
        >
            <RouterProvider router={router} />
            <NotificationQueue />
        </SWRConfig>
    );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Auth0Provider
        domain="auth.rivet.tax"
        clientId="CkVlAg9zAkVI1KtO8m5aQHJJdoOT7lkA"
        authorizationParams={{
            redirect_uri: window.location.origin + '/select',
        }}
    >
        <GlobalStateProvider>
            <App />
        </GlobalStateProvider>
    </Auth0Provider>
);
