import { init } from '@instantdb/react';
import { Cursors } from '@instantdb/react';
import React from 'react';

// Initialize the database with the appId
export const db = init({
    appId: process.env.REACT_APP_INSTANT_APP_ID,
});

// Generate a random dark color
export const randomDarkColor = () =>
    '#' +
    [0, 0, 0]
        .map(() =>
            Math.floor(Math.random() * 200)
                .toString(16)
                .padStart(2, '0')
        )
        .join('');

// Custom cursor component
export const CustomCursor = ({ color, name }) => (
    <span
        className="rounded-b-xl rounded-r-xl border-2 bg-white/30 px-3 text-xs shadow-lg backdrop-blur-md"
        style={{
            borderColor: color ?? 'gray',
        }}
    >
        {name}
    </span>
);

export const FormCursors = ({ room, userColor, children }) => (
    <Cursors
        room={room}
        renderCursor={(props) => (
            <CustomCursor color={props.color} name={props.presence.name} />
        )}
        userCursorColor={userColor}
    >
        {children}
    </Cursors>
);

// Avatars like Google Docs shows whose on the workstream
const Avatar = ({ name, color }) => (
    <div
        className={avatarClassNames}
        style={{
            borderColor: color,
        }}
    >
        {name?.charAt(0).toUpperCase()}
        <div className="hidden group-hover:flex absolute z-10 bottom-10 text-sm text-gray-800 bg-gray-200 rounded px-2">
            {name}
        </div>
    </div>
);

const avatarClassNames =
    'group relative select-none h-10 w-10 bg-gray-50 border-4 border-black rounded-full flex justify-center items-center -ml-2 first:ml-0';

export const AvatarStack = ({ room }) => {
    const presence = room.usePresence({ user: true });

    return (
        <div className="flex -space-x-2">
            {presence.user && (
                <Avatar
                    key="user"
                    name={presence.user.name || 'Unknown'}
                    color={presence.user.color}
                />
            )}
            {Object.entries(presence.peers).map(([id, peer]) => (
                <Avatar key={id} name={peer.name} color={peer.color} />
            ))}
        </div>
    );
};
